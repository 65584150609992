<template>
  <draggable
    class="dragArea ali2"
    tag="ul"
    :list="tasks"
    :group="{ name: 'g1' }"
    @change="log($event)"
    v-loading="loadingData"
  >
    <li v-for="(el, index) in tasks" :key="el.id">
      <div class="itme-blocks">{{ index + 1 }}. {{ el.name }}</div>
      <nested-draggable :tasks="el.tasks" />
    </li>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  props: {
    tasks: {
      required: true
    }
  },
  data() {
    return {
      loadingData: false
    };
  },
  components: {
    draggable
  },
  name: "nested-draggable",
  methods: {
    ...mapActions({
      updateOrder: "department/updateOrder"
    }),
    log: function(evt, index) {
      console.log(evt);
      let data = {
        departments: this.tasks
      };
      this.loadingData = true;
      this.updateOrder(data).then(res => {
        console.log(res);
        this.loadingData = false;
        this.$emit("updateList");
      });
    }
  }
};
</script>
<style lang="scss">
.dragArea {
  min-height: 50px;
  // outline: 1px dashed;
  li > ul {
    margin-left: 20px;
    min-height: 0px;
    outline: 1px;
    .itme-blocks {
      padding: 10px 7px;
      margin-bottom: 5px;
      border: 1px solid #c7c4c4;
      border-radius: 2px;
      color: #7d7d7d;
      font-size: 16px;
      cursor: all-scroll;
    }
  }
  .itme-blocks {
    padding: 7px;
    margin-bottom: 7px;
    border: 1px solid #0d67c9;
    background: #0d67c9;
    border-radius: 6px;
    color: #fff;
    font-size: 15px;
    cursor: all-scroll;
  }
}
</style>
